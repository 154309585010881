<template>
  <div class="wrapper">
    <el-row class="main">
      <aside class="menu-expanded" v-show="!collapsed">
        <!--导航菜单-->
        <div class="menu">
          <el-collapse v-model="activeNames" @change="handleChange">
            <el-collapse-item title="" name="1">
              <template slot="title"
                ><div class="subTit">
                  <el-icon class="el-icon-files"></el-icon>快捷菜单
                </div></template
              >
              <ul class="menuList">
                <li
                  @click="searchCus(item.val, 'quick')"
                  v-for="item in menuList"
                  :class="actived == item.val ? 'actived' : ''"
                  :key="item.val"
                >
                  {{ item.name }}
                </li>
              </ul>
            </el-collapse-item>
            <el-collapse-item title="" name="2">
              <template slot="title"
                ><div class="subTit">
                  <el-icon class="el-icon-collection-tag"></el-icon>订阅搜索
                </div></template
              >
              <ul class="menuList">
                <li
                  @click="searchCus(item.id, 'order')"
                  v-for="item in OrderSearchList"
                  :class="{ actived: actived == item.id }"
                  :key="item.id"
                >
                  {{ item.name }}
                  <div class="closed" @click.stop="delSearch(item.id)">
                    &times;
                  </div>
                </li>
              </ul>
            </el-collapse-item>
            <el-collapse-item title="" name="3">
              <template slot="title"
                ><div class="subTit">
                  <el-icon class="el-icon-folder-opened"></el-icon>文件夹
                  <div class="edit" @click.stop="editFavorite">编辑</div>
                </div></template
              >
              <el-collapse v-model="activeNames1">
                <el-collapse-item
                  name="1"
                  v-if="myFileData && myFileData.length > 0"
                >
                  <template slot="title"
                    ><div class="subTit_wj">
                      <i class="iconfont icon-lnav_wdwj"></i>我的文件夹
                    </div></template
                  >
                  <ul class="menuList">
                    <li
                      @click="searchCus(item.id, 'folder')"
                      v-for="item in myFileData"
                      :class="actived == item.id ? 'actived' : ''"
                      :key="item.id"
                    >
                      {{ item.name }}
                    </li>
                  </ul>
                </el-collapse-item>
                <el-collapse-item
                  name="2"
                  v-if="shareData && shareData.length > 0"
                >
                  <template slot="title"
                    ><div class="subTit_wj">
                      <i class="iconfont icon-rnav_gxwj"></i>共享文件夹
                    </div></template
                  >
                  <ul class="menuList">
                    <li
                      @click="searchCus(item.id, 'folder')"
                      v-for="item in shareData"
                      :class="actived == item.id ? 'actived' : ''"
                      :key="item.id"
                    >
                      {{ item.name }}
                    </li>
                  </ul>
                </el-collapse-item>
              </el-collapse>
            </el-collapse-item>
          </el-collapse>
        </div>
      </aside>
      <section
        class="content-container clearfix scollDom"
        :class="collapsed ? 'screen' : ''"
      >
        <el-col :span="24">
          <el-row class="searchBox">
            <el-form @submit.native.prevent :model="filter" ref="filter">
              <div class="fl searchL">
                <el-form-item label style="margin-bottom: 20px">
                  <el-input
                    class="search"
                    v-model="filter.filter"
                    @keyup.enter.native="submitForm('filter')"
                    placeholder="请输入公司名称 / 简称 / 行业 / 参与顾问，支持模糊搜索"
                  >
                    <el-button
                      type="primary"
                      slot="append"
                      @click="submitForm('filter')"
                      >搜 索</el-button
                    >
                  </el-input>
                </el-form-item>
              </div>
              <div class="other">
                <span class="order" @click="orderSearch">订阅搜索</span>
                <span class="more" @click="showMore = !showMore"
                  >{{ showMore ? "收起搜索" : "展开搜索"
                  }}<el-icon
                    :class="
                      showMore ? 'el-icon-arrow-up' : 'el-icon-arrow-down'
                    "
                  ></el-icon>
                </span>
              </div>
            </el-form>
            <div class="searchM" v-show="showMore">
              <el-form
                @submit.native.prevent
                :model="filter"
                ref="filter"
                class="clearfix"
                label-width="90px"
              >
                <el-form-item label="公司名称：" prop="name">
                  <el-input
                    placeholder="请输入公司名,精确查询"
                    v-model.trim="filter.name"
                    @keyup.enter.native="submitForm('filter')"
                    class="searchInput"
                    style="width: 240px"
                  >
                    <template slot="suffix">
                      <el-icon
                        class="el-icon-search"
                        @click.native="submitForm('filter')"
                      ></el-icon>
                    </template>
                  </el-input>
                </el-form-item>
                <el-form-item label="行业：" prop="industryIds">
                  <el-cascader
                    v-model="filter.industryIds"
                    :options="ColumnInList"
                    :props="props"
                    clearable
                    filterable
                    collapse-tags
                    :show-all-levels="false"
                    style="width: 240px"
                    placeholder="请选择"
                    @change="submitForm('filter')"
                  ></el-cascader>
                </el-form-item>
                <el-form-item label="类型：" prop="statusList">
                  <el-select
                    v-model="filter.statusList"
                    placeholder="请选择"
                    style="width: 240px"
                    clearable
                    filterable
                    multiple
                    collapse-tags
                    @change="submitForm('filter')"
                  >
                    <el-option
                      v-for="(item, index) in customerType"
                      :key="'a_' + index"
                      :value="item.value"
                      :label="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="参与顾问：" prop="consultantIds">
                  <el-select
                    v-model="filter.consultantIds"
                    placeholder="请选择"
                    style="width: 240px"
                    clearable
                    filterable
                    multiple
                    collapse-tags
                    @change="submitForm('filter')"
                  >
                    <el-option
                      v-for="i in userList"
                      :key="i.id"
                      :label="i.name"
                      :value="i.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="城市：" prop="cityIds">
                  <el-cascader
                    style="width: 240px"
                    v-model="filter.cityIds"
                    :options="ColumnCityList"
                    :props="props"
                    filterable
                    clearable
                    collapse-tags
                    @change="submitForm('filter')"
                    placeholder="请选择"
                  ></el-cascader>
                </el-form-item>
                <el-form-item label="标签：" prop="labelIds">
                  <el-select
                    v-model="filter.labelIds"
                    placeholder="请选择"
                    style="width: 240px"
                    clearable
                    filterable
                    multiple
                    collapse-tags
                    @change="submitForm('filter')"
                  >
                    <el-option
                      v-for="i in labelList"
                      :key="i.id"
                      :label="i.name"
                      :value="i.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-form>
            </div>
            <el-col class="table-top">
              <el-button type="primary" size="mini" @click="addNew">
                <i class="el-icon-plus"></i> 新增客户
              </el-button>
              <el-button type="default" plain size="mini" @click="addToFavorite"
                >批量加入文件夹</el-button
              >
              <el-button
                type="default"
                plain
                v-if="isShowDelBtn"
                size="mini"
                :disabled="isDisableDel"
                @click="removeFavorite"
                >从文件夹移出</el-button
              >
              <el-button
              v-if="hasMenuPage('contract:index:export')"
                size="mini"
                @click="geDetailExport"
                :loading="exportBtnLoading"
                >导出</el-button
              >
              <el-pagination
                :hide-on-single-page="filter.page.total > 10 ? false : true"
                style="padding: 0"
                v-if="filter.page.total > 0"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="filter.page.current"
                :page-size="filter.page.size"
                :pager-count="5"
                layout="total,prev, pager, next,sizes"
                :total="filter.page.total"
                :page-sizes="[10, 20, 50, 100]"
              ></el-pagination>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="24">
              <el-table
                :header-cell-style="{
                  'background-color': '#f5f7fa',
                  height: '35px',
                  'border-top': '1px #e5e5e5 solid',
                }"
                v-horizontal-scroll
                :data="ListData"
                v-sticky="{ top: 0, parent: '.scollDom' }"
                class="tabBorder custor"
                v-loading="listLoading"
                ref="handSelectTest_multipleTable"
                @selection-change="handleSelectionChange"
                style="margin-bottom: 20px"
              >
                <el-table-column
                  type="selection"
                  width="40"
                  align="center"
                ></el-table-column>
                <el-table-column
                  prop
                  min-width="220"
                  align="left"
                  class-name="canEdit"
                >
                  <template slot="header" slot-scope="scope">
                    公司名称
                    <el-popover
                      title="搜索"
                      popper-class="popperEdit"
                      placement="bottom"
                      trigger="click"
                    >
                      <i
                        slot="reference"
                        class="heardSearch el-icon-search"
                      ></i>
                      <div class="popcon">
                        <div class="popcon-input">
                          <el-input
                            size="mini"
                            v-model="filter.name"
                            placeholder="支持模糊搜索"
                          >
                            <el-button
                              slot="append"
                              icon="el-icon-search"
                              @click="submitForm('filter')"
                            ></el-button>
                          </el-input>
                        </div>
                      </div>
                    </el-popover>
                  </template>
                  <template slot-scope="scope">
                    <div>
                      <span
                        class="tabHref"
                        :title="
                          scope.row.name.length > 12 ? scope.row.name : ''
                        "
                        @click="detailsPop(scope.row.id)"
                        >{{ scope.row.name | caluTitle(12) }}</span
                      >
                      <i
                        @click.stop="showComEdit(scope.row.id)"
                        class="el-icon-edit tabHref"
                      ></i>
                    </div>
                    <p style="margin-top: 3px">
                      <span
                        class="tag"
                        v-for="(item, index) in scope.row.labels"
                        :key="index"
                        :style="
                          item.bordered
                            ? { 'border-color': item.color, color: item.color }
                            : {}
                        "
                        style="margin-bottom: 5px; margin-right: 5px"
                        v-html="item.name"
                        >{{ item.name }}</span
                      >
                    </p>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="status"
                  align="left"
                  min-width="120"
                  class-name="canEdit"
                >
                  <template slot="header" slot-scope="scope">
                    类型
                    <div
                      class="sortL"
                      v-if="!filter.ownCustomer"
                      :class="{
                        ascing: sortType == 'status' && sortCheck == 1,
                        descing: sortType == 'status' && sortCheck == 2,
                      }"
                    >
                      <i class="asc" @click.stop="sortWay('asc', 'status')"></i>
                      <i
                        class="desc"
                        @click.stop="sortWay('desc', 'status')"
                      ></i>
                    </div>
                    <el-popover
                      title="搜索"
                      popper-class="popperEdit"
                      placement="bottom"
                      trigger="click"
                      v-if="!filter.ownCustomer"
                    >
                      <i
                        slot="reference"
                        class="heardSearch el-icon-search"
                      ></i>
                      <div class="popcon">
                        <div class="popcon-input">
                          <el-select
                            v-model="filter.statusList"
                            filterable
                            multiple
                            collapse-tags
                            @change="submitForm('filter')"
                            placeholder="请选择"
                          >
                            <el-option
                              v-for="(item, index) in customerType"
                              :key="'b_' + index"
                              filterable
                              collapse-tags
                              :value="item.value"
                              :label="item.value"
                            >
                            </el-option>
                          </el-select>
                        </div>
                      </div>
                    </el-popover>
                  </template>
                  <template slot-scope="scope">
                    <div>
                      <div class="tableType">
                        <span
                          class="tag"
                          :class="{
                            tag01: scope.row.status == 'trial_case',
                            tag02: scope.row.status == '开发中',
                            tag03: scope.row.status == '已签约',
                            tag04: scope.row.status == '签约中',
                            tag05: scope.row.status == '终止合作',
                            tag06: scope.row.status == '合同到期',
                            tag07: scope.row.status == '普通公司',
                          }"
                          >{{ scope.row.status }}</span
                        >
                      </div>
                      <el-popover
                        placement="bottom"
                        title="编辑"
                        popper-class="popperEdit"
                        @show="getComInfoById(scope.row.id)"
                        trigger="click"
                      >
                        <i slot="reference" class="el-icon-edit tabHref"></i>
                        <div class="popcon">
                          <div class="popcon-input">
                            <el-select
                              filterable
                              v-model="company.company.status"
                              :disabled="!company.hasResetPermission"
                              placeholder="请选择"
                            >
                              <el-option
                                v-for="(item, index) in customerType"
                                :key="'c_' + index"
                                :value="item.value"
                                :label="item.value"
                                :disabled="
                                  (item.value == '开发中' &&
                                    !company.hasResetDevelop) ||
                                  (item.value == 'trial_case' &&
                                    !company.hasReseTrialCase) ||
                                  (item.value == '终止合作' &&
                                    !company.hasReseStopCooperate) ||
                                  item.value == '签约中' ||
                                  item.value == '合同到期' ||
                                  item.value == '已签约'
                                "
                              >
                              </el-option>
                            </el-select>
                          </div>
                          <div class="subBtn" v-if="company.hasResetPermission">
                            <el-button
                              size="mini"
                              @click="cancel"
                              icon="el-icon-close"
                            ></el-button
                            ><el-button
                              type="primary"
                              size="mini"
                              icon="el-icon-check"
                              @click="checkVal"
                            ></el-button>
                          </div>
                        </div>
                      </el-popover>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="industryName"
                  align="left"
                  min-width="160"
                  class-name="canEdit"
                >
                  <template slot="header" slot-scope="scope">
                    行业
                    <el-popover
                      placement="bottom"
                      title="搜索"
                      popper-class="popperEdit"
                      trigger="click"
                    >
                      <i
                        slot="reference"
                        class="heardSearch el-icon-search"
                      ></i>
                      <div class="popcon">
                        <div class="popcon-input">
                          <el-cascader
                            v-model="filter.industryIds"
                            :options="ColumnInList"
                            :props="props"
                            clearable
                            style="width: 240px"
                            filterable
                            collapse-tags
                            :show-all-levels="false"
                            @change="submitForm('filter')"
                            placeholder="请选择"
                          ></el-cascader>
                        </div>
                      </div>
                    </el-popover>
                  </template>
                  <template slot-scope="scope">
                    <div>
                      <span
                        v-if="scope.row.industryName"
                        :title="
                          scope.row.industryName.length > 12
                            ? scope.row.industryName
                            : ''
                        "
                        >{{ scope.row.industryName | caluTitle(12) }}</span
                      >
                      <span v-else>-</span>
                      <el-popover
                        placement="bottom"
                        popper-class="popperEdit"
                        title="编辑"
                        @show="getComInfoById(scope.row.id)"
                        trigger="click"
                      >
                        <i slot="reference" class="el-icon-edit tabHref"></i>
                        <div class="popcon">
                          <div class="popcon-input">
                            <el-cascader
                              v-model="company.companyIndustryIds"
                              :options="ColumnInList"
                              :props="props"
                              style="width: 240px"
                              filterable
                              collapse-tags
                              :show-all-levels="false"
                              placeholder="请选择"
                            ></el-cascader>
                          </div>
                          <div class="subBtn">
                            <el-button
                              size="mini"
                              @click="cancel"
                              icon="el-icon-close"
                            ></el-button
                            ><el-button
                              type="primary"
                              size="mini"
                              @click="checkVal"
                              icon="el-icon-check"
                            ></el-button>
                          </div>
                        </div>
                      </el-popover>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column min-width="120" class-name="canEdit">
                  <template slot="header" slot-scope="scope">
                    项目数量
                    <div
                      class="sortL"
                      :class="{
                        ascing: sortType == 'projectCount' && sortCheck == 1,
                        descing: sortType == 'projectCount' && sortCheck == 2,
                      }"
                    >
                      <i
                        class="asc"
                        @click.stop="sortWay('asc', 'projectCount')"
                      ></i>
                      <i
                        class="desc"
                        @click.stop="sortWay('desc', 'projectCount')"
                      ></i>
                    </div>
                    <el-popover
                      placement="bottom"
                      title="搜索"
                      popper-class="popperEdit"
                      trigger="click"
                    >
                      <i
                        slot="reference"
                        class="heardSearch el-icon-search"
                      ></i>
                      <div class="popcon">
                        <div class="popcon-input">
                          <el-input
                            type="number"
                            v-model.number="filter.projectCount"
                            placeholder="请输入数字"
                            size="mini"
                          >
                            <el-button
                              @click="submitForm('filter')"
                              slot="append"
                              icon="el-icon-search"
                            ></el-button>
                          </el-input>
                        </div>
                      </div>
                    </el-popover>
                  </template>
                  <template slot-scope="scope">
                    <span
                      class="tabHref"
                      @click="
                        scope.row.projectCount ? toProject(scope.row.id) : ''
                      "
                      >{{ scope.row.projectCount }}</span
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  prop="creatorName"
                  align="left"
                  class-name="canEdit"
                  min-width="130"
                >
                  <template slot="header" slot-scope="scope">
                    创建者
                    <el-popover
                      placement="bottom"
                      title="搜索"
                      popper-class="popperEdit"
                      trigger="click"
                    >
                      <i
                        slot="reference"
                        class="heardSearch el-icon-search"
                      ></i>
                      <div class="popcon">
                        <div class="popcon-input">
                          <el-select
                            v-model="filter.createUserIds"
                            clearable
                            filterable
                            multiple
                            collapse-tags
                            @change="submitForm('filter')"
                            placeholder="请选择"
                          >
                            <el-option
                              v-for="i in userList"
                              :key="i.id"
                              :label="i.name"
                              :value="i.id"
                            >
                            </el-option>
                          </el-select>
                        </div>
                      </div>
                    </el-popover>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="lastModifiedTime"
                  width="180"
                  align="left"
                  class-name="canEdit"
                >
                  <template slot="header" slot-scope="scope">
                    最新更新时间
                    <div
                      class="sortL"
                      :class="{
                        ascing:
                          sortType == 'lastModifiedTime' && sortCheck == 1,
                        descing:
                          sortType == 'lastModifiedTime' && sortCheck == 2,
                      }"
                    >
                      <i
                        class="asc"
                        @click.stop="sortWay('asc', 'lastModifiedTime')"
                      ></i>
                      <i
                        class="desc"
                        @click.stop="sortWay('desc', 'lastModifiedTime')"
                      ></i>
                    </div>
                    <el-popover
                      placement="bottom"
                      title="搜索"
                      popper-class="popperEdit"
                      trigger="click"
                    >
                      <i
                        slot="reference"
                        class="heardSearch el-icon-search"
                      ></i>
                      <div class="popcon">
                        <div class="popcon-input">
                          <el-date-picker
                            v-model="rangTime"
                            type="daterange"
                            value-format="yyyy-MM-dd"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            @change="submitForm('filter')"
                          >
                          </el-date-picker>
                        </div>
                      </div>
                    </el-popover>
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination
                :hide-on-single-page="filter.page.total > 10 ? false : true"
                v-if="filter.page.total > 0"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="filter.page.current"
                :page-size="filter.page.size"
                :pager-count="5"
                layout="total,prev, pager, next,sizes"
                :total="filter.page.total"
                :page-sizes="[10, 20, 50, 100]"
                style="margin-bottom: 20px"
              ></el-pagination>
            </el-col>
          </el-row>
        </el-col>
      </section>
    </el-row>
    <el-dialog
      :visible.sync="visibleCom"
      :modal-append-to-body="false"
      append-to-body
      title="编辑"
      :close-on-click-modal="false"
      width="600px"
    >
      <el-form
        @submit.native.prevent
        :model="company"
        ref="company"
        :validate-on-rule-change="false"
        label-width="100px"
      >
        <el-form-item prop="company.name" label="客户名称：">
          <el-input
            placeholder="请输入内容"
            v-model="company.company.name"
            maxlength="100"
            class="comCls"
          >
            <template slot="append">
              <el-icon
                class="el-icon-search"
                @click.native="getComInfo(company.company.name)"
              ></el-icon>
            </template>
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visibleCom = false" size="small">取 消</el-button>
        <el-button type="primary" size="small" @click="checkVal('com')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-drawer
      :visible.sync="drawerVisible"
      custom-class="drawerClass"
      direction="ttb"
      :modal="false"
      append-to-body
      :before-close="handleClose"
    >
      <div slot="title" class="drawerTitle">
        <i v-if="drawerTitle == '客户'" class="drawIcon iconfont icon-kh_xq"></i
        >{{ drawerTitle }}
      </div>
      <div class="drawerCon">
        <addoreEditCus
          v-if="drawerTitle == '新增客户' || drawerTitle == '编辑客户'"
          @loadList="loadList"
          @tuComInfo="detailsPop"
          :customId="customId"
        ></addoreEditCus>
        <customerInfo
          v-if="drawerTitle == '客户'"
          @toEdit="toEditComInfo"
          :customId="customId"
          :activeToInfo="activedToInfo"
          @loadList="loadList"
        ></customerInfo>
      </div>
    </el-drawer>
    <el-dialog
      title="查询结果"
      :visible.sync="dialogVisible"
      width="720px"
      append-to-body
      :before-close="handleCloseResult"
    >
      <ul class="comList">
        <li v-for="item in fuzzyItems" :key="item.id">
          <div class="comName">
            <p>{{ item.name }}</p>
          </div>
          <span>{{ item.base }}</span
          ><span class="tabHref fr" @click="checkCom(item)">选择</span>
        </li>
        <li v-for="item in specialItems" :key="item">
          <div class="comName">
            <p>{{ item }}</p>
            <span class="specicon">特殊公司</span>
          </div>
          <span class="tabHref fr" @click="checkCom(item)">选择</span>
        </li>
      </ul>
    </el-dialog>
    <el-dialog
      :visible.sync="orderSearchVisible"
      :modal-append-to-body="false"
      append-to-body
      title="订阅搜索"
      :close-on-click-modal="false"
      width="600px"
    >
      <el-form
        @submit.native.prevent
        :model="searchFrom"
        ref="searchFrom"
        style="padding: 0 50px"
        :rules="rules"
        :validate-on-rule-change="false"
        label-width="0"
        label-position="right"
      >
        <el-form-item prop="name">
          <el-input
            placeholder="请给订阅搜索取个名字"
            v-model.trim="searchFrom.name"
            maxlength="12"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            resetForm('searchFrom');
            orderSearchVisible = false;
          "
          size="small"
          >取 消</el-button
        >
        <el-button
          type="primary"
          size="small"
          :loading="isSubscribeSearch"
          @click="submitOrder('searchFrom')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="fileManagement"
      :modal-append-to-body="false"
      append-to-body
      lock-scroll
      title="文件夹管理"
      @close="updateFavoriteList"
      :close-on-click-modal="false"
      width="720px"
    >
      <file
        v-if="fileManagement"
        @getFavoriteData="childByValue"
        @length="getLength"
        :type="type"
        :checkbox="isCheckbox"
        @delFile="delFile"
      ></file>
      <span
        slot="footer"
        class="dialog-footer"
        v-if="isCheckbox.edit && isShowSaveBtn"
      >
        <el-button
          :disabled="favoriteList.favoriteIds.length == 0"
          size="mini"
          :loading="saveloading"
          @click="saveFavorite"
          type="primary"
          >保存</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  GetCustomerPagedList,
  getCommonColumnList, //获取行业 城市列表
  getUserBaseInfos, //获取用户列表
  customerOptions, //获取客户标签
  GetCustomerDetailById, //获取编辑详情
  GetFuzzyQuery, //校验公司名称
  addOrEditCustomer, //编辑保存
  GetBusinessInfo,
  SearchAddOrEdit, //新增-编辑订阅管理
  GetSearchPage, //获取订阅管理列表
  GetSearchById, //根据ID获取方案
  removeFromFavorite,
  deleteSearchById,
  addToFavorite, //通过id删除方案
  getListFavorite, //获取文件夹list
  cityTree,
  getCompanyExport, //导出客户列表
} from "../../api/api";
import { mapGetters } from "vuex";
import file from "../../components/file/file";
import addoreEditCus from "./../../components/customer/addorEditCustomer";
import customerInfo from "./../../components/customer/customerInfo";
import * as data from "../../common/json/commData.json";
import { ApiBaseUrl, PreviewUrl } from "@/api/http";
export default {
  name: "customer",
  components: { addoreEditCus, customerInfo, file },
  data() {
    return {
      baseUrl: ApiBaseUrl,
      activedToInfo: "1",
      myFileData: [],
      shareData: [],
      OrderSearchList: [],
      multipleSelection: [],
      orderSearchVisible: false,
      visibleCom: false,
      isShowSaveBtn: false,
      canSaveCom: false,
      isDisableDel: true,
      favoriteList: {
        favoriteIds: [],
        itemIds: [],
      },
      isCheckbox: {
        val: true,
        edit: false,
      },
      searchFrom: {
        id: null,
        name: "",
        searchList: {},
        type: "客户",
        version: 0,
      },
      rules: {
        name: [
          { required: true, trigger: "blur", message: "请给订阅搜索取个名字" },
        ],
      },
      type: "客户",
      showMore: false,
      dialogVisible: false,
      specialItems: [],
      fuzzyItems: [],
      creditCode: "",
      delFiles: {
        favoriteId: "",
        itemIds: [],
      },
      fileManagement: false,
      activeNames: ["1", "2", "3"],
      activeNames1: ["1", "2"],
      actived: "我的客户",
      menuList: [
        {
          name: "我的客户",
          val: "我的客户",
        },
        {
          name: "所有客户",
          val: "all",
        },
      ],
      customId: "", //客户id
      activeToInfo: "",
      showSearchTab: false,
      customerType: data.customerType,
      isShowDelBtn: false,
      listLoading: false,
      filter: {
        filter: "",
        name: "",
        cityIds: [],
        favoriteId: "",
        labelIds: [],
        // consultantIds: [],
        consultantUserIds: [],
        createUserIds: [],
        id: null,
        ids: [],
        industryIds: [],
        lastModifiedEnd: "",
        lastModifiedStart: "",
        ownCustomer: false,
        projectCount: null,
        statusList: [],
        userId: null,
        page: {
          asc: [],
          ascs: [],
          countId: "",
          desc: [],
          descs: [],
          hitCount: true,
          maxLimit: null,
          optimizeCountSql: true,
          orders: [
            {
              asc: false,
              column: "lastModifiedTime",
            },
          ],
          current: 1,
          size: 50,
          searchCount: true,
          pages: null,
          records: [],
          total: 0,
        },
      },
      company: {
        companyIndustryIds: [],
        company: {
          name: "",
          status: "",
        },
      },
      drawerVisible: false,
      HsearchList: [],
      ListData: [],
      ColumnCityList: [],
      ColumnInList: [],
      userList: [],
      labelList: [],
      sortCheck: null,
      sortType: "",
      props: {
        value: "id",
        label: "name",
        children: "children",
        multiple: true,
      },
      showFavorBtn: false,
      rangTime: [],
      drawerTitle: "",
      isSubscribeSearch: false,
      saveloading: false,
      exportBtnLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      collapsed: "upDateCollapsed",
    }),
  },
  created() {
    if (!this.$route.query.order && !this.$route.query.folder) {
      if (this.$route.query.ownCustomer) {
        //带参刷新
        this.filter.ownCustomer = eval(this.$route.query.ownCustomer);
        this.actived = this.$route.query.status;
      } else {
        //初始默认
        this.filter.ownCustomer = true;
      }
      this.isDisableDel = false;
      this.isShowDelBtn = false;
    } else {
      this.actived = this.$route.query.order || this.$route.query.folder;
      if (this.$route.query.order) {
        //订阅搜索
        this.isDisableDel = false;
        this.isShowDelBtn = false;
        GetSearchById(this.actived).then((res) => {
          if (res.success) {
            this.filter = res.result.searchList;
          }
        });
      }
      if (this.$route.query.folder) {
        //文件夹
        this.filter.favoriteId = this.actived;
        this.delFiles.favoriteId = this.actived;
        this.isDisableDel = true;
        this.isShowDelBtn = true;
      }
    }
    this.loadOrderSearchList();
    this.loadList();
    this.loadColumnInList("行业");
    this.loadColumnCityList("城市");
    this.loadUserBase();
    this.loadLabelList();
    this.getListFavorite(); //左边文件夹列表
    if (this.$route.query.comId) {
      //项目跳客户详情
      this.drawerVisible = true;
      this.drawerTitle = "客户";
      this.customId = this.$route.query.comId; //客户id
    }
  },
  watch: {
    drawerVisible(newValue) {
      if (newValue) {
        this.noScroll(); //禁止滚动
      } else {
        this.canScroll();
      }
    },
    "company.companyIndustryIds"(newValue, oldValue) {
      if (newValue.length > 5) {
        this.$message({
          message: "最多不超过5个",
          duration: 1500,
          type: "error",
        });
        this.$nextTick(() => {
          this.company.companyIndustryIds = oldValue;
        });
      }
    },
    "$route.query"(newValue, oldValue) {
      if (this.$route.query.comId) {
        //项目跳客户详情
        this.drawerVisible = false;
        this.drawerTitle = "";
        setInterval(() => {
          this.drawerVisible = true;
          this.drawerTitle = "客户";
          this.customId = this.$route.query.comId; //客户id
        }, 100);
      }
      if (!newValue.order && !newValue.folder) {
        if (newValue.ownCustomer) {
          this.filter.ownCustomer = eval(newValue.ownCustomer);
          this.actived = newValue.status;
        } else {
          this.actived = "开发中";
          this.filter.ownCustomer = true;
        }
        this.isDisableDel = false;
        this.isShowDelBtn = false;
        this.loadList();
      } else {
        //订阅&&文件夹
        this.actived = newValue.order || newValue.folder;
        if (newValue.order) {
          this.isDisableDel = false;
          this.isShowDelBtn = false;
          GetSearchById(this.actived).then((res) => {
            if (res.success) {
              this.filter = res.result.searchList;
              this.loadList();
            }
          });
        }
        if (newValue.folder) {
          //文件夹
          this.filter.favoriteId = this.actived;
          this.delFiles.favoriteId = this.actived;
          this.filter.ownCustomer = false;
          this.isDisableDel = true;
          this.isShowDelBtn = true;
          this.loadList();
        }
      }
    },
  },
  methods: {
    //删除文件夹监听
    delFile(id) {
      if (id == this.$route.query.folder) {
        this.$message.info("该文件夹已经删除，将跳转到所有客户");
        this.searchCus("all", "quick");
      }
    },
    // 列表
    getListFavorite() {
      getListFavorite({ type: this.type }).then((res) => {
        this.shareData = res.result.shareList;
        this.myFileData = res.result.myList;
      });
    },
    updateFavoriteList() {
      this.getListFavorite();
    },
    loadOrderSearchList() {
      GetSearchPage({ type: "客户" }).then((res) => {
        if (res.success) {
          this.OrderSearchList = res.result;
        }
      });
    },
    addToFavorite() {
      if (this.multipleSelection && this.multipleSelection.length > 0) {
        this.fileManagement = true;
        this.isCheckbox.edit = true;
      } else {
        this.$message.error("请先选择公司");
      }
    },
    saveFavorite() {
      this.saveloading = true;
      addToFavorite(this.favoriteList).then((res) => {
        this.fileManagement = false;
        this.$message.success(res.message);
        this.saveloading = false;
      });
    },
    childByValue(childValue) {
      this.favoriteList.favoriteIds = childValue;
    },
    getLength(val) {
      if (val) {
        this.isShowSaveBtn = true;
      } else {
        this.isShowSaveBtn = false;
      }
    },
    submitOrder(formName) {
      this.isSubscribeSearch = true;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.searchFrom.searchList = this.filter;
          SearchAddOrEdit(this.searchFrom).then((res) => {
            if (res.success) {
              this.$message.success(res.message);
              this.orderSearchVisible = false;
              this.loadOrderSearchList();
              this.resetForm("searchFrom");
              this.isSubscribeSearch = false;
            }
          });
        } else {
          this.isSubscribeSearch = false;
          return false;
        }
      });
    },
    toEditComInfo(id) {
      this.drawerTitle = "编辑客户";
      this.customId = id;
    },
    toProject(id) {
      this.drawerVisible = true;
      this.drawerTitle = "客户";
      this.customId = id; //客户id
      this.activedToInfo = "4";
    },
    detailsPop(id) {
      this.drawerVisible = true;
      this.drawerTitle = "客户";
      this.customId = id; //客户id
      this.activedToInfo = "1";
    },

    submitForm() {
      if (this.rangTime && this.rangTime.length > 0) {
        this.filter.lastModifiedStart = this.rangTime[0];
        this.filter.lastModifiedEnd = this.rangTime[1];
      } else {
        this.filter.lastModifiedStart = "";
        this.filter.lastModifiedEnd = "";
      }
      this.filter.page.current = 1;
      this.loadList();
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    cancel() {
      document.body.click();
    },
    loadList(name) {
      window.scrollTo(0, 0);
      this.listLoading = true;
      if (this.drawerTitle != "客户") {
        this.drawerVisible = false;
      }
      if (name == "edit") {
        //详情跳编辑客户点击取消
        this.drawerTitle = "客户";
        this.drawerVisible = true;
        this.activedToInfo = "1";
      }
      if (name == "flie") {
        //详情关闭文件夹弹窗
        this.getListFavorite();
      }
      GetCustomerPagedList(this.filter).then((res) => {
        if (res.success) {
          this.listLoading = false;
          this.ListData = res.result.records;
          if (this.filter.favoriteId && this.ListData.length) {
            this.isShowDelBtn = true;
          }
          this.filter.page.total = res.result.total;
        }
        this.listLoading = false;
      });
    },
    showComEdit(id) {
      this.visibleCom = true;
      this.getComInfoById(id);
    },
    loadUserBase() {
      //获取用户列表
      getUserBaseInfos(this.filter).then((res) => {
        if (res.success) {
          this.userList = res.result;
        }
      });
    },
    loadLabelList() {
      //获取标签列表
      customerOptions().then((res) => {
        if (res.success) {
          this.labelList = res.result;
        }
      });
    },
    loadColumnInList(type) {
      //行业 城市列表
      this.ColumnInList = [];
      getCommonColumnList({ type: type }).then((res) => {
        if (res.success) {
          this.ColumnInList = res.result;
        }
      });
    },
    loadColumnCityList(type) {
      //行业 城市列表
      this.ColumnCityList = [];
      cityTree().then((res) => {
        if (res.success) {
          this.ColumnCityList = res.result;
        }
      });
    },
    delSearch(id) {
      //删除订阅搜索
      this.$confirm("确定删除该订阅?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: "altCls",
        type: "warning",
      })
        .then(() => {
          deleteSearchById(id).then((res) => {
            if (res.success) {
              this.$message.success(res.message);
              this.loadOrderSearchList();
              if (this.actived == id) {
                this.reset();
                this.actived = "开发中";
                this.filter.ownCustomer = true;
                this.loadList();
              }
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    addNew() {
      //新增客户
      this.customId = null;
      this.drawerVisible = true;
      this.drawerTitle = "新增客户";
    },
    handleCloseResult() {
      this.dialogVisible = false;
      this.canSaveCom = false;
    },
    handleClose(done) {
      if (this.drawerTitle == "编辑客户") {
        this.drawerTitle = "客户";
      } else {
        this.drawerTitle = "";
        this.drawerVisible = false;
      }
    },
    orderSearch() {
      //订阅搜索
      this.orderSearchVisible = true;
    },
    sortWay(name, type) {
      //升降序
      this.sortType = type;
      this.sortCheck = null;
      this.filter.page.orders[0].column = type;
      if (name == "asc") {
        this.sortCheck = this.sortCheck == 1 ? null : 1;
        this.filter.page.orders[0].asc = true;
      }
      if (name == "desc") {
        this.sortCheck = this.sortCheck == 2 ? null : 2;
        this.filter.page.orders[0].asc = false;
      }
      if (this.sortCheck == null) {
        this.filter.page.orders[0].asc = "";
        this.filter.page.orders[0].asc = "";
        this.filter.page.orders[0].column = "";
      }
      this.loadList();
    },
    getComInfo(val) {
      //校验公司名
      if (val) {
        GetFuzzyQuery({ keyword: val }).then((res) => {
          if (res.success) {
            this.specialItems = res.result.specialItems;
            this.fuzzyItems = res.result.fuzzyItems;
            this.dialogVisible = true;
          }
        });
      }
    },
    checkCom(item) {
      if (typeof item == "object") {
        GetBusinessInfo({
          comId: this.customId,
          companyType: item.companyType,
          id: item.id,
          name: item.name,
        }).then((res) => {
          if (res.success) {
            if (res.result.error_code == 0) {
              this.company.company.address = res.result.registeredAddress;
              this.company.company.creditCode = res.result.creditCode;
              this.company.company.name = item.name;
              this.canSaveCom = true;
              this.dialogVisible = false;
            } else if (res.result.error_code == 2) {
              this.$confirm("该公司已创建，请选择是否查看?", "提示", {
                confirmButtonText: "查看",
                cancelButtonText: "取消",
                customClass: "altCls",
                type: "warning",
              })
                .then(() => {
                  this.dialogVisible = false;
                  this.detailsPop(res.result.comId);
                })
                .catch(() => {
                  this.$message({
                    type: "info",
                    message: "已取消",
                  });
                });
            } else {
              this.$alert(res.result.reason, "提示", {
                confirmButtonText: "确定",
                customClass: "altCls",
                callback: (action) => {},
              });
            }
          }
        });
      } else {
        this.company.company.name = item;
        this.dialogVisible = false;
        this.canSaveCom = true;
      }
    },
    getComInfoById(id) {
      //获取编辑详情
      this.customId = id;
      GetCustomerDetailById(id).then((res) => {
        this.formLoading = false;
        if (res.success) {
          this.company = res.result;
          this.company.allCityIds = eval(res.result.company.cityIds);
          this.creditCode = res.result.company.creditCode;
          this.$set(this.company, "companyIndustryIds", []);
          if (
            res.result.companyIndustryList &&
            res.result.companyIndustryList.length > 0
          ) {
            res.result.companyIndustryList.forEach((item, index) => {
              if (item.industryIds) {
                this.company.companyIndustryIds[index] = eval(item.industryIds);
              }
            });
          }
        }
      });
    },
    checkVal(type) {
      //修改内容
      if (type == "com") {
        if (this.canSaveCom) {
          //已获取天眼查数据
          if (this.company.company.name) {
            //修改内容
            addOrEditCustomer(this.company).then((res) => {
              if (res.success) {
                this.$message.success(res.message);
                this.canSaveCom = false;
                this.visibleCom = false;
                this.loadList();
              }
            });
          } else {
            this.$message.error("内容不能为空");
          }
        } else {
          //未获取
          this.getComInfo(this.company.company.name);
        }
      } else {
        if (
          this.company.companyIndustryIds == [] ||
          this.company.companyIndustryIds.length == 0
        ) {
          this.$message.error("修改内容不能为空");
          return false;
        }
        addOrEditCustomer(this.company).then((res) => {
          if (res.success) {
            this.$message.success(res.message);
            document.body.click();
            this.canSaveCom = false;
            this.visibleCom = false;
            this.loadList();
          }
        });
      }
    },
    editFavorite() {
      //编辑收藏夹
      this.fileManagement = true;
      this.isCheckbox.edit = false;
    },
    removeFavorite() {
      //移出收藏夹
      this.$confirm("确定移出选中项?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: "altCls",
        type: "warning",
      })
        .then(() => {
          removeFromFavorite(this.delFiles).then((res) => {
            if (res.success) {
              this.$message.success(res.message);
              this.loadList(this.HsearchList);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleSizeChange(val) {
      //pageSize 改变时会触发
      this.filter.page.size = val;
      this.filter.page.current = 1;
      this.loadList();
    },
    handleCurrentChange(val) {
      //currentPage 改变时会触发
      this.filter.page.current = val;
      this.loadList();
    },
    handleChange() {},
    handleChange1() {},
    handleChange2() {},
    reset() {
      this.rangTime = [];
      this.filter = {
        filter: "",
        cityIds: [],
        consultantUserIds: [],
        createUserIds: null,
        id: null,
        ids: [],
        industryIds: [],
        lastModifiedEnd: "",
        lastModifiedStart: "",
        ownCustomer: false,
        projectCount: null,
        status: "",
        statusList: [],
        labelIds: [],
        userId: null,
        page: {
          asc: [],
          ascs: [],
          countId: "",
          desc: [],
          descs: [],
          hitCount: true,
          maxLimit: null,
          optimizeCountSql: true,
          orders: [
            {
              asc: false,
              column: "lastModifiedTime",
            },
          ],
          current: 1,
          size: 50,
          searchCount: true,
          pages: null,
          records: [],
          total: 0,
        },
      };
    },
    searchCus(val, type) {
      if (this.actived != val) {
        this.actived = val;
        this.sortType = "";
        this.sortCheck = null;
        switch (type) {
          case "quick": //快捷菜单
            this.reset();
            this.$router.push({
              path: "/customer",
              query: {
                ownCustomer: val != "all" ? true : false,
                status: val,
              },
            });
            break;
          case "order":
            this.$router.push({
              path: "/customer",
              query: {
                order: val,
              },
            });
            break;
          case "folder":
            this.reset();
            this.$router.push({
              path: "/customer",
              query: {
                folder: val,
              },
            });
            break;
        }
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;

      if (val.length) {
        this.isDisableDel = false;
        this.delFiles.itemIds = val.map((i) => i.id);
      } else {
        this.isDisableDel = true;
      }
      this.favoriteList.itemIds = val.map((i) => i.id);
    },
    //详情列表数据
    geDetailExport() {
      this.exportBtnLoading = true;
      let setData = false;
      let isconfirm = true;
      var int = setTimeout(function () {
        let that = this;
        setData = true;
        clearInterval(int);
        let div =
          "<p style='font-weight:bold'>后台处理中，稍后请在 " +
          "<a href='/tools/exportingRecords' style='color:#526ecc'>导出记录</a>" +
          " 获取文件</p>";
        if (isconfirm) {
          that.$globalHub
            .$confirm(div, "提示", {
              showCancelButton: false,
              showConfirmButton: false,
              customClass: "altCls",
              dangerouslyUseHTMLString: true,
            })
            .then(() => {})
            .catch(() => {});
        }
      }, 5000);
      getCompanyExport(this.filter).then((res) => {
        if (res.success) {
          if (!setData) {
            isconfirm = false;
            location.href = this.baseUrl + res.result;
          }
        }
        this.exportBtnLoading = false;
      });
    },
  },
  destroyed() {
    this.canScroll();
  },
  mounted() {
    this.noScroll(); //禁止滚动
  },
};
</script>

<style lang="scss" scoped>
@import "../collapsed.less";
.searchM {
  margin-bottom: 5px;
  .el-form-item {
    float: left;
    margin-bottom: 15px;
  }
}
.searchL {
  width: calc(100% - 162px);
}
.search {
  height: 35px;
  line-height: 35px;
  :deep(.el-input__inner) {
    height: 35px !important;
    line-height: 35px !important;
    border-color: #e60012 !important;
  }
  :deep(.el-input-group__append) {
    border-color: #e60012 !important;
    background-color: #e60012;
    height: 33px !important;
    line-height: 33px !important;
    color: #fff;
    font-weight: bold;
  }
  :deep(.el-input-group__append:hover) {
    background-color: #cc0000;
  }
}
.other {
  line-height: 35px;
  font-size: 13px;
  float: right;
  span {
    margin: 0;
    cursor: pointer;
    color: #606266;
    &:hover {
      color: #e60012;
    }
  }
  .order {
    padding: 0 8px;
    border-right: 1px solid #e5e5e5;
  }
  .more {
    padding-left: 8px;
    i {
      margin-left: 8px;
    }
  }
}
.tableType {
  display: inline-block;
}
.comList {
  max-height: 400px;
  overflow: auto;
  li {
    height: 32px;
    line-height: 32px;
    padding: 0 35px 0 15px;
    cursor: pointer;
    &:hover {
      background-color: #f0f0f0;
    }
    .comName {
      width: 80%;
      float: left;
      p {
        max-width: 60%;
        float: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      span {
        height: 20px;
        width: 62px;
        text-align: center;
        line-height: 20px;
        display: inline-block;
        color: #efa14d;
        border: 1px solid #efa14d;
        border-radius: 3px;
        margin-left: 8px;
      }
    }
  }
}
</style>

<style lang="scss">
.comCls {
  .el-input__suffix {
    top: 2px !important;
  }
  .el-input-group__append {
    background: #e60012;
    color: #fff;
    border: 1px #e60012 solid;
  }
}
</style>